import $ from 'jquery'
$(document).ready( initMainSlider )

function initMainSlider(){
  if ($('.js-slider').length) {
    console.log('initMainSlider')
    $('.js-slider').slick({
      arrows: true,
      dots: true,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }

  if ($('.js-slider-logos').length) {
    console.log('initLogoSlider')
    $('.js-slider-logos').slick({
      arrows: true,
      dots: false,
      autoplay: true,
      speed: 2000,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }
}
