import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
    el.parent().next().slideToggle('fast');
    el.parent().parent().toggleClass('active');
  } else {
    el.attr('aria-expanded', 'true');
    el.parent().next().slideToggle('fast');
    el.parent().parent().toggleClass('active');
  }  
}
function toggleAccordionDetailsItem() {
  let el = $(this);
  if (el.parent().parent().hasClass('active')) {
    el.attr('aria-expanded', 'false');
    el.parent().next().find('.item__content__details-wrap').slideToggle('fast');
    el.parent().parent().toggleClass('active');
  } else {
    el.attr('aria-expanded', 'true');
    el.parent().next().find('.item__content__details-wrap').slideToggle('fast');
    el.parent().parent().toggleClass('active');
  }
}
function toggleAccordionItemHover() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.parent().parent().toggleClass('active');
  } else {
    el.parent().parent().toggleClass('active');
  }  
}

$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
$doc.on('click', '.js-accordion-details-item button', toggleAccordionDetailsItem)
$doc.on('hover', '.js-accordion-item button', toggleAccordionItemHover )